import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";

import PageHeader from "@containers/page-header/layout-01";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import UseScroll from "../../containers/scroll";
import QuoteForm from "@containers/translation-quote-form";
import TranslateImageBox from "@containers/landing/translate-image-box/layout-03"
const Footer =lazy(()=>import("@layout/footer/layout-01"))

const GlobalSolutionsPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 200) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="Globalisation solutions"
        description="Andovar offers a comprehensive range of translation services spanning text, audio and visual platforms — delivered via innovative technologies."
      />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <QuoteForm />
        <TranslateImageBox data={content["translate-images-body"]} />
        
        {
          showRestComponents &&
        <CaseStudy data={caseStudiesData} />
}
      </main>
      {
         showRestComponents &&  <Suspense fallback={<div></div>}>
         <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
    }
    </Layout>
  );
};

export const query = graphql`
  query globalsolutionsPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "globalisation-solutions" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

GlobalSolutionsPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default GlobalSolutionsPage;
